<template>
  <div>
    <b-card>
      <b-row>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label for="start-date">From</label>
          <b-form-datepicker
            id="start-date"
            v-model="startDate"
            reset-button
            class="mb-2"
            @input="endDate=setEndDate(startDate, endDate)"
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label for="end-date">To</label>
          <b-form-datepicker
            id="end-date"
            v-model="endDate"
            reset-button
            class="mb-2"
            @input="startDate=setStartDate(startDate, endDate)"
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="align-self-center"
        >
          <div class="d-flex align-content-center">
            <b-button
              variant="primary"
              @click="getRequestedPeriodInvoiceList()"
            >
              <b-spinner
                v-if="isTableLoading"
                small
              />
              Set Period
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <b-card no-body>
      <div class="m-2">
        <b-row class="justify-content-between">
          <b-col
            cols="12"
            md="6"
          >
            <b-button
              variant="primary"
              class="mb-md-0 mb-2"
              target="_blank"
              @click="$router.push('/sales/invoice/create')"
            >
              Create Invoice
            </b-button>
          </b-col>
          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
                @input="debounceSearch(meta.offset = 0, getRequestedQuery)"
              />
              <v-select
                v-model="statusQuery"
                :options="statusOptions"
                :reduce="option => option.value"
                class="status-filter-select w-75"
                placeholder="Select Status"
                @input="getRequestedQuery"
              />
            </div>
          </b-col>
        </b-row>
      </div>
      <b-table
        responsive
        primary-key="id"
        show-empty
        empty-text="No matching records found"
        :items="invoiceList"
        :fields="tableColumns"
        :busy="isTableLoading"
      >
        <template #table-busy>
          <div class="text-center text-primary m-2">
            <b-spinner class="align-middle" />
            <strong> Loading...</strong>
          </div>
        </template>
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            toggle-class="p-0"
            class="action-menu"
            no-caret
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              @click="getSelectedInvoiceForCancellation(data.item)"
            >
              <feather-icon icon="XIcon" />
              <span class="align-middle ml-50">Cancel Invoice</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
        <template #cell(sale_id)="data">
          <b-link
            :href="`/sales/invoice/preview/${data.item.id}`"
            target="_blank"
          >
            {{ data.item.sale_id }}
          </b-link>
        </template>
        <template #cell(status)="data">
          <b-badge
            pill
            class="cursor-pointer"
            :variant="formatClassName(data.item.status)"
          >
            {{ data.item.status ? formatStatus(data.item.status) : 'N/A' }}
          </b-badge>
        </template>
        <template #cell(client)="data">
          <p class="mb-0">
            {{ data.item.customer_first_name }} {{ data.item.customer_last_name }}
          </p>
          <small class="text-muted">
            {{ data.item.customer_phone }}
          </small>
        </template>
      </b-table>
      <TablePagination
        :offset="meta.offset"
        :total="meta.total"
        :limit="meta.limit"
        :list-data="invoiceList"
        @paginate="paginate"
      />
    </b-card>
    <!-- cancel invoice modal -->
    <b-modal
      id="cancel-invoice-modal"
      ref="cancel-invoice-modal"
      ok-only
      ok-variant="primary"
      modal-class="modal-primary"
      centered
      title="Cancel Invoice"
    >
      <b-card-text class="text-center">
        Are you sure you want to cancel this invoice?
      </b-card-text>
      <template #modal-footer>
        <b-button
          variant="primary"
          :disabled="isApiInProgress"
          @click="cancelInvoice"
        >
          <b-spinner
            v-if="isApiInProgress"
            small
          />
          Confirm
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment'
import api from '@/libs/api'
import helper from '@/mixins/helper'
import calendar from '@/mixins/calendar'
import TablePagination from '@/components/common/TablePagination.vue'

export default {
  name: 'InvoiceList',
  components: {
    TablePagination,
  },
  mixins: [helper, calendar],
  data() {
    return {
      startDate: moment(new Date()).subtract(30, 'days').format('YYYY-MM-DD'),
      endDate: moment(new Date()).format('YYYY-MM-DD'),
      searchQuery: '',
      statusQuery: '',
      isTableLoading: false,
      isApiInProgress: false,
      selectedInvoice: {},
      invoiceList: [],
      meta: {
        offset: 0,
        limit: 10,
        total: 0,
      },
      tableColumns: [
        { key: 'actions', label: '', thClass: 'sm' },
        {
          key: 'sale_id',
          label: 'SALE ID',
          thClass: 'md',
        },
        {
          key: 'created_at',
          label: 'CREATED AT',
          sortable: true,
          formatter: value => (value ? this.formatDate(value) : 'N/A'),
          thClass: 'md',
        },
        {
          key: 'status',
          label: 'STATUS',
          thClass: 'sm',
        },
        {
          key: 'client',
          label: 'Customer',
          thClass: 'md',
        },
        {
          key: 'agent_details.email',
          label: 'Created By',
          thClass: 'md',
        },
        {
          key: 'total_payable',
          label: 'PAYABLE',
          formatter: value => `BDT ${Number(value).toLocaleString()}`,
          thClass: 'sm',
        },
        {
          key: 'total_paid',
          label: 'Paid',
          formatter: value => `BDT ${Number(value).toLocaleString()}`,
          thClass: 'sm',
        },
        {
          key: 'total_due',
          label: 'Due',
          formatter: value => `BDT ${Number(value).toLocaleString()}`,
          thClass: 'sm',
        },
      ],
      statusOptions: [
        { label: 'Pending', value: 'PENDING' },
        { label: 'Paid', value: 'PAID' },
        { label: 'Partial', value: 'PARTIAL' },
        { label: 'Cancelled', value: 'CANCELLED' },
      ],
    }
  },
  computed: {
    filterQuery() {
      const startDate = this.startDate ? `&start_date=${this.startDate}` : ''
      const endDate = this.endDate ? `&end_date=${this.endDate}` : ''
      const search = this.searchQuery ? `&search=${this.searchQuery}` : ''
      const status = this.statusQuery ? `&status=${this.statusQuery}` : ''
      return `${startDate}${endDate}${search}${status}`
    },
  },
  mounted() {
    this.getInvoiceList()
  },
  methods: {
    getInvoiceList() {
      this.isTableLoading = true
      api.getData(`b2e_dashboard/stores/invoices/?offset=${this.meta.offset}${this.filterQuery}`, true).then(response => {
        if (response.data.status) {
          this.invoiceList = response.data.result.results
          this.meta = response.data.result.meta
        } else {
          this.notificationMessage('danger', 'XIcon', 'Error', response.data.error.message)
        }
      }).catch().finally(() => {
        this.isTableLoading = false
      })
    },
    getSelectedInvoiceForCancellation(invoice) {
      this.selectedInvoice = invoice
      this.$refs['cancel-invoice-modal'].show()
    },
    cancelInvoice() {
      this.isApiInProgress = true
      api.updateData(`b2e_dashboard/stores/invoices/${this.selectedInvoice.id}/`, true, {
        status: 'CANCELLED',
      }).then(response => {
        if (response.data.status) {
          this.notificationMessage('success', 'CheckIcon', 'Success', 'Your request has been successfully processed')
          this.getInvoiceList()
          this.$refs['cancel-invoice-modal'].hide()
        } else {
          this.notificationMessage('danger', 'XIcon', 'Error', response.data.error.message)
        }
      }).catch(() => {
        this.notificationMessage('danger', 'XIcon', 'Error', 'Sorry, something went wrong')
      }).finally(() => {
        this.isApiInProgress = false
      })
    },
    getRequestedQuery() {
      this.isTableLoading = true
      this.meta.offset = 0
      this.getInvoiceList()
    },
    getRequestedPeriodInvoiceList() {
      this.isTableLoading = true
      this.meta.offset = 0
      this.getInvoiceList()
    },
    paginate(offset) {
      this.meta.offset = offset
      this.getInvoiceList()
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/style.scss';
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
